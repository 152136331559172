import React from 'react';
import { Redirect } from 'react-router-dom';
import { ProgressRoute as Route } from './progress-route';
import { useAuth } from '../../context/auth';
import { ContainerPage } from '../styles';
import { Navbar } from '../navbar';

function PrivateRoute({ component: Component, ...rest }) {
    const { tokens } = useAuth();

    return (
        <Route
            {...rest}
            render={(props) =>
                tokens?.token ? (
                    <ContainerPage>
                        <Navbar />
                        <Component {...props} />
                    </ContainerPage>
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { referer: props.location },
                        }}
                    />
                )
            }
        />
    );
}

export { PrivateRoute };
