import gql from 'graphql-tag';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink, Observable } from 'apollo-link';

const env = process.env.NODE_ENV;

const setHeaders = (refresh = false) => {
    const token = localStorage.getItem('x-token');
    const refreshToken = localStorage.getItem('x-refresh-token'); // Solamente refrescando el token
    let headers = {
        'x-token': token || '',
    };
    if (refresh) {
        headers['x-refresh-token'] = refreshToken;
    }
    return headers;
};
const link = new HttpLink({
    uri: env === 'development' ? '/graphql' : 'https://api.delfino.cr/graphql',
    headers: setHeaders(),
});
const refreshLink = new HttpLink({
    uri: env === 'development' ? '/graphql' : 'https://api.delfino.cr/graphql',
    headers: setHeaders(true),
});
const REFRESH_TOKEN = gql`
    query REFRESH_TOKEN {
        refreshTokens {
            token
            refreshToken
        }
    }
`;

function refreshTokens() {
    return new ApolloClient({
        link: refreshLink,
        cache: new InMemoryCache(),
    })
        .query({
            query: REFRESH_TOKEN,
        })
        .then(({ data }) => {
            console.log('refrescados');
            localStorage.setItem('x-token', data.refreshTokens.token);
            localStorage.setItem(
                'x-refresh-token',
                data.refreshTokens.refreshToken
            );
        })
        .catch((err) => {
            client.resetStore();
            return err;
        });
}
const error = ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
        for (let i = 0; i < graphQLErrors.length; i++) {
            if (graphQLErrors[i].extensions.code === 'UNAUTHENTICATED') {
                return new Observable((observer) => {
                    refreshTokens()
                        .then(() => {
                            operation.setContext({
                                headers: setHeaders(),
                            });
                            const subscriber = {
                                next: observer.next.bind(observer),
                                error: observer.error.bind(observer),
                                complete: observer.complete.bind(observer),
                            };
                            return forward(operation).subscribe(subscriber);
                        })
                        .catch((error) => {
                            observer.error(error);
                        });
                });
            }
        }
    }
};
const client = new ApolloClient({
    link: ApolloLink.from([onError((event) => error(event)), link]),
    cache: new InMemoryCache(),
});

export default client;
