import React from 'react';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Page, Form, Button, Title } from '../../components/styles';
import { Spinner, Error, Modal } from '../../components';
import errorFormatter from '../../utils/error-formatter';
import useVisible from '../../hooks/useModal';

const CLEAR_CACHE_POST_MUTATION = gql`
    mutation ClearCachePost(
        $key: String!
    ) {
        clearCachePost(key:$key)
    }
`;

const INITIAL_STATE = {
    key: ''
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'key':
            return Object.assign({}, state, {
                key: action.value,
            });
        default:
            return state;
    }
};

function ClearCache() {
    const [formData, dispatch] = React.useReducer(reducer, INITIAL_STATE);
    const [errorMessage, setError] = React.useState(null);
    const [visible, toggle] = useVisible(false);

    const [clearCacheMutation, { loading, error, data }] = useMutation(
        CLEAR_CACHE_POST_MUTATION
    );

    const formHandler = (e) => {
        e.preventDefault();
        toggle(true)
        clearCacheMutation({ variables: formData });
    };

    const inputHandler = (e) => {
        const { type, name, value } = e.target;
        const val = type === 'number' ? parseFloat(value) : value;
        dispatch({ type: name, value: val });
    };

    if (error) {
        console.log(error);
    }

    return (
        <Page>
            <Title>Clear cache nota</Title>
            <p>Utilizar slug o ID de la nota</p>
            <Form onSubmit={formHandler}>
                {/* {createCouponData.error && <Error error={errorMessage} />} */}
                <fieldset
                    disabled={loading}
                    aria-busy={loading}
                >
                    <label htmlFor="name">
                        Slug / ID
                        <input
                            type="text"
                            id="key"
                            name="key"
                            required
                            value={formData.slug}
                            onChange={inputHandler}
                        />
                    </label>
                    <Button type="submit">Limpiar cache</Button>
                </fieldset>
            </Form>
            <Modal visible={visible && data} hide={toggle}>
                {
                    data && <p>{data.clearCachePost}</p>
                }
            </Modal>
        </Page>
    );
}

export default ClearCache;
