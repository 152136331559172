import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';
import { Button } from '../components/styles';
import { Error, PostTable } from '../components/';
import errorFormatter from '../utils/error-formatter';
const columns = ['Thumbnail', 'ID', 'Slug', 'Creado'];

const SEARCH_POST = gql`
    query SEARCH_POST($slug: String) {
        post(slug: $slug) {
            ID
            slug
            image {
                thumbnail
            }
            created
        }
    }
`;

const SearchPost = ({ inputHandler, search, postHandler }) => {
    const [errorMsg, setError] = useState(null);
    const [selected, setSelected] = useState(null);
    const [searchPostQuery, { loading, error, data }] = useLazyQuery(
        SEARCH_POST,
        {
            onError: (error) => errorFormatter(error, setError),
        }
    );
    const onClickSearch = (e) => {
        e.preventDefault();
        searchPostQuery({
            variables: {
                slug: search.trim(),
            },
        });
    };
    const onClickRemove = (e) => {
        e.preventDefault();
        setSelected(null);
        postHandler(null);
    };
    const onClickPost = (row, index) => {
        setSelected(row[1]);
        postHandler(row[1]);
    };
    const showTable = () => {
        if (data) {
            return (
                <PostTable
                    columns={columns}
                    rows={[data.post]}
                    onClick={onClickPost}
                />
            );
        }
        return <></>;
    };
    const showSearch = () => {
        if (!selected) {
            return (
                <fieldset disabled={loading} aria-busy={loading}>
                    {error && <Error error={errorMsg} />}
                    <label htmlFor="post">
                        Buscador de Posts
                        <input
                            type="text"
                            id="post"
                            name="search"
                            placeholder="Busque por slug"
                            required
                            value={search}
                            onChange={(e) => inputHandler(e)}
                        />
                    </label>
                    <Button
                        style={{ width: '100%' }}
                        type="button"
                        onClick={onClickSearch}
                    >
                        Buscar Post
                    </Button>
                </fieldset>
            );
        }
        return (
            <>
                <Button
                    style={{ width: '100%' }}
                    type="button"
                    onClick={onClickRemove}
                >
                    Eliminar Post
                </Button>
            </>
        );
    };
    return (
        <>
            {showSearch()}
            {showTable()}
        </>
    );
};
export { SearchPost };
