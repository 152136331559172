import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Error, Spinner } from '../../../components';
import Data from './data';
import Edit from './edit';

const GET_BOLLETINES = gql`
    query GET_BOLLETINES($email: String!) {
        myBulletins(email: $email)
    }
`;

const list = [
    {
        name: 'Las noticias de ayer, hoy ',
        id: '4d001f3827'
    },
    {
        name: 'Súper (lunes)',
        id: '7fe794d2e6'
    },
    {
        name: 'La Jornada (lunes)',
        id: '78598b04e8'
    },
    {
        name: 'Reporte Delfino + (martes a viernes)',
        id: '8cc59bbac8'
    },
    // {
    //     name: 'Vortex (jueves)',
    //     id: 'f2a61e7349'
    // },
    {
        name: 'Resumen Barra de Prensa (sábados)',
        id: 'd03c440802'
    },
    {
        name: 'Repaso Dominical (domingos)',
        id: '3562191010'
    }
];

const Boletines = ({ email }) => {
    const [modeEdit, setModeEdit] = useState(false);
    const { loading, error, data } = useQuery(GET_BOLLETINES, {
        variables: {
            email
        },
    });

    if (loading) {
        return <Spinner />;
    }

    if (error || !data) {
        return <Error error={error} />;
    }

    const { myBulletins } = data;

    if(modeEdit){
        return <Edit email={email} myBulletins={myBulletins} list={list} setModeEdit={setModeEdit}/>
    }else{
        return <Data myBulletins={myBulletins} list={list} setModeEdit={setModeEdit}/>
    }
};
export default Boletines;
