import React from 'react';
import styled from 'styled-components';

const StyledTable = styled.table`
    table-layout: fixed;
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    letter-spacing: 1px;
    overflow-x: auto;
`;

const Th = styled.th`
    color: #565a6e;
    padding: 0.6rem;
    font-size: 14px;
`;

const Tr = styled.tr`
    border-top: 1px solid #bfbfdc;
    margin-top: 2px;
    font-size: 15px;
    color: ${(props) => (props.highlight ? '#607EFB' : '#7171a6')};
    cursor: ${(props) => (props.onClick ? 'pointer' : 'auto')};
    &:hover {
        background-color: #7ea3e678;
    }
`;
const Tdc = styled.td`
    padding: 0.9rem;
    cursor: ${(props) => (props.onClick ? 'pointer' : 'auto')};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
const Td = styled.td`
    padding: 0.9rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const renderHeaderColumns = ({ columns }) =>
    columns?.map((column, index) => <Th key={index}>{column}</Th>);

const renderBodyRows = ({ rows, onClick, columnsHighlight }) =>
    rows?.map((row, index) => (
        <Tr key={index} highlight={columnsHighlight?.includes(index)}>
            {renderRowColumns(row, onClick)}
        </Tr>
    ));

const renderRowColumns = (row, onClick) =>
    row.map((column, index) => {
        if (index === 0 || index === 1) {
            return (
                <Tdc
                    onClick={onClick ? () => onClick(row, index) : null}
                    key={index}
                >
                    {column}
                </Tdc>
            );
        }
        return <Td key={index}>{column}</Td>;
    });

function Table(props) {
    return (
        <StyledTable>
            <thead>
                <tr>{renderHeaderColumns(props)}</tr>
            </thead>
            <tbody>{renderBodyRows(props)}</tbody>
        </StyledTable>
    );
}

export { Table };
