import React from 'react';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { Page, Title, Card, Form, Button } from '../components/styles';
import { Error, UserTable } from '../components/';
import errorFormatter from '../utils/error-formatter';

const GET_USERS_QUERY = gql`
    query GET_USERS_QUERY($search: String!) {
        getUsers(search: $search) {
            count
            values {
                ID
                name
                email
                subscription {
                    status
                    title
                    subscription_token
                }
            }
        }
    }
`;

function Users() {
    const [search, setSearch] = React.useState('');
    const history = useHistory();
    const [errorMessage, setError] = React.useState(null);
    const [getUsersQuery, { loading, error, data }] = useLazyQuery(
        GET_USERS_QUERY,
        {
            onError: (error) => errorFormatter(error, setError),
        }
    );
    const formHandler = (e) => {
        e.preventDefault();
        getUsersQuery({ variables: { search } });
    };
    const onClickUser = ([id]) => history.push(`users/${id}`);
    const showTableData = () => {
        if (!data?.getUsers?.values) {
            return null;
        }

        if (data?.getUsers?.values.length === 0) {
            return <p>No se encontraron usuarios</p>;
        }

        return (
            <Card style={{ marginTop: '40px' }}>
                <UserTable
                    onClick={onClickUser}
                    rows={data?.getUsers?.values}
                />
            </Card>
        );
    };

    return (
        <Page>
            <Title>Usuarios</Title>
            <Form onSubmit={formHandler}>
                <fieldset disabled={loading} aria-busy={loading}>
                    {error && <Error error={errorMessage} />}
                    <label htmlFor="email">
                        Correo
                        <input
                            type="text"
                            id="search"
                            name="search"
                            placeholder="Busqueda"
                            required
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </label>
                    <Button style={{ width: '100%' }} type="submit">
                        Buscar
                    </Button>
                </fieldset>
            </Form>
            {showTableData()}
        </Page>
    );
}

export { GET_USERS_QUERY };
export default Users;
