import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import styled from 'styled-components';
import { Container, Header, Title, Button } from '../../../components/styles/';


const Li = styled.li`
    display: flex;
    align-items: center;
    label{
        margin-left: 5px;
    }
`;

const Row = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    h4,p{
        margin: 0;
    }
    p{
        margin-left:10px;
    }
`;

function Boletines({ myBulletins, list, setModeEdit }) {

    return (
        <Container className="mb">
            <Title>Boletines</Title>
            <ul>
                {
                    list.map(({name, id})=>{
                        const checked = myBulletins.includes(id);
                        return (
                            <Li> 
                                <input type="checkbox" id={id} name={id} value={name} checked={checked} />
                                <label for={id}>{name}</label><br/>
                            </Li>
                        )
                    })
                }
            </ul>
            <Row>
                <Button style={{ width: '200px' }} onClick={(e)=> setModeEdit(true)}>
                    Editar
                </Button>
            </Row>
        </Container>
    );
}

export default Boletines;