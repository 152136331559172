import React from 'react';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import { Form, Button } from '../../../components/styles';
import { Modal, Error } from '../../../components';
import useVisible from '../../../hooks/useModal';
import { CSVLink, CSVDownload } from "react-csv";

const GET_DATA_TYPE = gql`
    query GET_DATA_TYPE($type: String!){
        getUserType(type: $type) {
            ID
            subscription_id
            user_id
            activation_date
            description
            platform
            card_token
            active
            card
            subscription_token
            brand
            debtor
            password
            email
            name
            documentId
            academicDegree
            interests
            phone
            whatsappMsg
            codeCountryPhone
        }
    }
`;

function UserByType() {
    const { id } = useParams();
    const [visible, toggle] = useVisible(true);
    const [type, setType] = React.useState('');
    const [dataCSV, setData] = React.useState(null);
    const [getDataUserType, { loading, error, data }] = useLazyQuery(
        GET_DATA_TYPE,{
            onCompleted: ()=>{
                const { getUserType } = data;
                console.log(data, 'data');
                setData(getUserType);
            }
        }
    );

    const formHandler = (e) => {
        e.preventDefault();
        // if (window.confirm(`Confirmar descargar svg  - ${type}`)) {
            getDataUserType({ variables: { type } });
        // }
    };

    const inputHandler = (e) => {
        const { value } = e.target;
        setType(value);
    };
    
    if (error) {
        console.log(error);
    }

    return (
        <>
            <Form onSubmit={formHandler}>
                {error && <Error error={error} />}
                <fieldset disabled={loading} aria-busy={loading}>
                    <label htmlFor="type">
                        Descargar usarios tipo:
                        <select
                            name="type"
                            required
                            value={type}
                            onChange={inputHandler}
                        >
                            <option value=" ">Seleccionar</option>
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                            <option value="en-cobro">En cobro</option>
                            <option value="moroso">Moroso</option>
                            <option value="boletin-whatsapp">Boletin whatsapp</option>
                        </select>
                    </label>
                    {
                        type && !dataCSV && <Button type="submit"  backgroundColor="green">Obtener información</Button>
                    }
                    {
                        dataCSV && <CSVLink
                        data={dataCSV}
                        filename={`${type}.csv`}
                        target="_blank"
                      >
                        Descargar CSV
                      </CSVLink>
                    }
                    
                </fieldset>
            </Form>
        </>
    );
}

export default UserByType;
