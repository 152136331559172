import React from 'react';
import RequestPassword from './actions/request-password';
import ChangePassword from './actions/change-password';
import CancelSuscription from './actions/cancel-suscription';
import InactiveUser from './actions/inactive-user';
import ActiveSinpe from './actions/active-sinpe';
import ActiveSinpeMensual from './actions/active-sinpe-mensual';
import ActiveTeamDelfino from './actions/active-team-delfino';



function Actions({status}) {
    return (
        <div>
            <ActiveSinpe />
            <ActiveSinpeMensual />
            <ActiveTeamDelfino />
            { status > 0 && status < 4 && <CancelSuscription/> }
            { status !== 0  && <InactiveUser/> }
            <RequestPassword />
            <ChangePassword />
        </div>
    );
}

export default Actions;
