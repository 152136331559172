import React from 'react';

function Error({ error }) {
    const msg = error
        ? error
        : 'Error si este mensaje persiste después de hacer otra solicitud refresque la página!';
    return <p>{msg}</p>;
}

export { Error };
