import React from 'react'
import styled from 'styled-components'
import { PaginationButton } from './styles'

const StyledPagination = styled.div`
  display: flex;
  justify-content: center;
  margin: 1.2rem;
`

const P = styled.p`
  margin-left: 1rem;
  margin-right: 1rem;
  color: #000;
`

function Pagination({ page, count, itemsPerPage, pathname }) {
  const pages = Math.ceil(count / itemsPerPage)
  return (
    <StyledPagination>
      <PaginationButton
        to={{
          pathname: pathname,
          search: `page=${page - 1}`,
        }}
        disabled={page <= 1}
      >
        &#x2190;
      </PaginationButton>
      <P>
        Pagina {page} de {pages}
      </P>
      <PaginationButton
        to={{
          pathname: `/${pathname}`,
          search: `page=${page + 1}`,
        }}
        disabled={page >= pages}
      >
        &#8594;
      </PaginationButton>
    </StyledPagination>
  )
}

export { Pagination }
