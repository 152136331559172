import styled from 'styled-components'
import { Link } from 'react-router-dom'

const Button = styled.button`
  background-color: ${(props) => props.backgroundColor || '#607EFB'};
  padding: 1rem 3rem;
  color: #ffffff;
  font-size: 1rem;
  border: 0px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`

const PaginationButton = styled(Link)`
  padding: 1rem 0.5rem;
  text-decoration: none;
  color: #000;
  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }
  &:hover {
    opacity: 0.8;
  }
`

export { Button, PaginationButton }
