import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { useParams } from "react-router-dom";
import { Page, Title } from '../../components/styles';
import { Error, Spinner } from '../../components';
// import EditInfo from './edit-info';

import Information from './information';
import Suscripcion from './suscripcion';
import Factura from './factura';
import Boletines from './boletines';
import Actions from './actions';
import { Fragment } from 'react';

const GET_USER_QUERY = gql`
    query GET_USER_QUERY($ID: Int!) {
        getUserInfo(ID: $ID) {
            user{
                isSpecial
                email
                name
                ID
                isSpecial
                whatsappMsg
                codeCountryPhone
                phone
                interests
                subscription{
                    status
                    subscription_token
                    card_token
                    activationDate
                    nextPayment
                    title
                    price
                    nextPayment
                    description
                    card
                    brand
                    platform
                }
            }
        }
    }
`;

// subs{
//     id
//     user_id
//     status
//     description
//     currency
//     amount
//     startdate
//     enddate
//     cadence
//     next_payment_date
//     brand
//     total_revenue
//     last4
//     payments{
//             payment_date
//     }
// }

function User() {
    let { id } = useParams();
    const { loading, error, data } = useQuery(GET_USER_QUERY, {
        variables: { 
            ID: parseInt(id, 10)
        },
    });

    if (loading) {
        return <Spinner />;
    }

    if (error) {
        return <Error error={error} />;
    }

    if (!data || !data.getUserInfo) {
        return <Error error={'Usuario no existe'} />;
    }


    console.log(data, "data");

    let statusUser = -1;
    const { getUserInfo } = data;
    const { user } = getUserInfo;
    const { subscription } = user;
    
    if (subscription) {
        const { status } = subscription;
        statusUser = status;
    }

    return (
        <Page>
            <Title>Información del usuario:</Title>
            <Information data={user} status={statusUser}/>
            { subscription && statusUser > 0 && <Suscripcion data={subscription}/> }
            { subscription && statusUser > 0 && <Factura userId={id}/> }
            <Boletines email={user.email} />
            <Actions status={statusUser}/>
            <br />
            <br />
        </Page>
    );
}

export default User;
