import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import { Form, Button } from '../../../components/styles';
import { Modal, Error } from '../../../components';
import useVisible from '../../../hooks/useModal';

const ACTIVE_ACCOUNT_SINPE = gql`
    mutation ACTIVE_ACCOUNT_SINPE($ID: Int!){
        activeAccountSINPE(ID: $ID) {
            message
        }
    }
`;

function ACTIVESINPE() {
    const { id } = useParams();
    const [visible, toggle] = useVisible(true);
    const [requestActiveAccountSINPE, { loading, error, data }] = useMutation(
        ACTIVE_ACCOUNT_SINPE
    );

    const formHandler = (e) => {
        e.preventDefault();
        if (window.confirm("Confirmar Activar cuenta SINPE")) {
            requestActiveAccountSINPE({ variables: { ID: parseInt(id, 10) } });

        }
    };
    if (error) {
        console.log(error);
    }

    return (
        <>
            <Form onSubmit={formHandler}>
                {error && <Error error={error} />}
                <fieldset disabled={loading} aria-busy={loading}>
                    <label htmlFor="password">Activar cuenta sinpe anual</label>
                    <Button type="submit"  backgroundColor="green">Activar</Button>
                </fieldset>
            </Form>
            <Modal visible={visible && data} hide={toggle}>
                {
                    data && <p>{data.activeAccountSINPE.message}</p>
                }
            </Modal>
        </>
    );
}

export default ACTIVESINPE;
