import React, { useState } from 'react';
import Data from './data';
import Edit from './edit';


const Information = ({ data, status }) => {
    const [modeEdit, setModeEdit] = useState(false);

    if(modeEdit){
        return <Edit data={data} setModeEdit={setModeEdit}/>
    }else{
        return <Data data={data} status={status} setModeEdit={setModeEdit}/>
    }
};
export default Information;
