import React from 'react';
import gql from 'graphql-tag';
import styled from 'styled-components';
import { uniqueId } from 'lodash/util';
import { useMutation } from '@apollo/react-hooks';
import { Container, Title, Button } from '../../../components/styles/';
import { Modal, Error } from '../../../components';
import useVisible from '../../../hooks/useModal';


const Li = styled.li`
    display: flex;
    align-items: center;
    label{
        margin-left: 5px;
    }
    input{
        width: auto;
    }
`;

const Row = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    form{
        border: 0;
        padding: 0px;
    }
    h4,p{
        margin: 0;
    }
    p{
        margin-left:10px;
    }
`;

const Fieldset = styled.fieldset`
    border: 0;
    margin: 0;
    padding: 0;
`;

const UPDATE_BOLETINES = gql` 
    mutation UPDATE_BOLETINES($bulletins: [String], $email: String){
        updateMyBulletins(bulletins: $bulletins, email: $email)
    }
`;


const ShowList = ({list, bullets, inputHandler,...props}) => {
    return (
        <ul>
            {
                list.map(({ name, id }) => {
                    const checked = bullets.includes(id);
                    return (
                        <Li key={uniqueId()}>
                            <input type="checkbox" id={id} name={id} value={name} checked={checked} onChange={inputHandler} />
                            <label for={id}>{name}</label><br />
                        </Li>
                    )
                })
            }
        </ul>
    )
};

function Boletines({ email, myBulletins, list, setModeEdit }) {
    const [bullets, setBullets] = React.useState(myBulletins);
    const [visible, toggle] = useVisible(true);

    const [updateBoletines, { loading, error, data }] = useMutation(UPDATE_BOLETINES);

    const formHandler = (e) => {
        console.log('formHandler', bullets);
        console.log('formHandler email', email);
        
        e.preventDefault();
        updateBoletines({
            variables:{
                bulletins: bullets,
                email
            }
        });
    };

    const inputHandler = (e) => {
        const { name, value, checked } = e.target;
        const isInclude = bullets.includes(name);
        let a = [];

        if (isInclude) {
            a = bullets.filter(i => i !== name);
        } else {
            a = [name].concat(bullets);
        }
        setBullets(a);
    };

    return (
        <Container className="mb">
            <Row>
                <form onSubmit={formHandler}>
                    <Fieldset disabled={loading} aria-busy={loading}>
                        {error && <Error error={error} />}
                        <Title>Boletines editar</Title>
                        <ShowList list={list} bullets={bullets} inputHandler={inputHandler} />
                        <Row>
                            <Button style={{ width: '200px' }}>
                                Actualizar
                            </Button>
                            <Button style={{ width: '200px', marginLeft: '15px' }} backgroundColor="green" onClick={(e) => setModeEdit(false)}>
                                Regresar
                            </Button>
                        </Row>
                    </Fieldset>
                </form>
            </Row>
            <Modal visible={visible && data} hide={toggle}>
                <p>Se han actualizado la información de las listas correctamente</p>
            </Modal>
        </Container>
    );
}

export default Boletines;