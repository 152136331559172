import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import { Container, Header, Title, Button, Form } from '../../../components/styles/';
import { Modal, Error } from '../../../components';
import useVisible from '../../../hooks/useModal';

const P = styled.p`
    color: #565a6e;
    font-size: 14px;
`;

const Row = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    h4,p{
        margin: 0;
    }
    p{
        margin-left:10px;
    }
`;

const UPDATE_USER = gql`
    mutation UPDATE_USER($email: String, $name: String!, $ID: Int!) {
        updateUser(email: $email, name: $name, ID: $ID) {
            email
            name
        }
    }
`;

const Edit = ({  setModeEdit, ...props }) => {
    const { id } = useParams();
    const [formData, setFormData] = React.useState({
        email: props.data.email ,
        name: props.data.name,
    });
    const [visible, toggle] = useVisible(true);

    const [updateUser, { loading, error, data }] = useMutation(
        UPDATE_USER
    );

    const formHandler = (e) => {
        e.preventDefault();
        let updateData = {
            name: formData.name,
            ID: parseInt(id, 10)
        }
        if(props.data.email !== formData.email) updateData.email = formData.email;

        updateUser({ variables: updateData });
    };

    const inputHandler = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    return (
        <>
            <Container className="mb">
                <Form onSubmit={formHandler}>
                    <fieldset disabled={loading} aria-busy={loading}>
                        {error && <Error error={error} />}
                        <Row>
                            <Header> Email:</Header>
                            <P>
                                <input
                                    type='Email'
                                    id='email'
                                    name='email'
                                    required
                                    value={formData.email}
                                    onChange={inputHandler}
                                />
                            </P>
                        </Row>
                        <Row>
                            <Header> Nombre:</Header>
                            <P>
                                <input
                                    type='text'
                                    id='name'
                                    name='name'
                                    required
                                    value={formData.name}
                                    onChange={inputHandler}
                                />
                            </P>
                        </Row>

                        <Row>
                            <Button style={{ width: '200px' }}>
                                Actualizar
                            </Button>
                            <Button style={{ width: '200px', marginLeft: '15px' }} backgroundColor="green" onClick={(e)=> setModeEdit(false)}>
                                Regresar
                            </Button>
                        </Row>
                    </fieldset>
                </Form>
                <Modal visible={visible && data} hide={toggle}>
                    <p>Se han actualizado los datos correctamente</p>
                </Modal>
            </Container>
        </>
    );
};
export default Edit;
