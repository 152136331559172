import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { Page, Form, Button, Title } from '../components/styles';
import {  Error, Modal } from '../components';
import errorFormatter from '../utils/error-formatter';
import useVisible from '../hooks/useModal';

const CREATE_USER_MUTATION = gql`
    mutation CREATE_USER_MUTATION(
        $email: String!
        $name: String!
    ) {
        activeUserAndOrderDisable(
            email: $email
            name: $name
        ) {
            message
            userType
        }
    }
`;

const INITIAL_STATE = {
    email: '',
    name: '',
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'email':
            return Object.assign({}, state, {
                email: action.value.toLowerCase(),
            });
            case 'name':
            return Object.assign({}, state, {
                name: action.value
            });
        default:
            return state;
    }
};

function CreateUser() {
    const [formData, dispatch] = React.useReducer(reducer, INITIAL_STATE);
    const [visible, toggle] = useVisible(true);

    const [errorMessage, setError] = React.useState(null);
    const [createUserMutation, { loading, error, data }] = useMutation(
        CREATE_USER_MUTATION,
        {
            onError: (error) => errorFormatter(error, setError),
        }
    );

    const formHandler = (e) => {
        e.preventDefault();
        createUserMutation({ variables: formData });
    };

    const inputHandler = (e) => {
        const { type, name, value } = e.target;
        const val = type === 'number' ? parseFloat(value) : value;
        dispatch({ type: name, value: val });
    };

    
    // if (data) {
    //     return <Redirect to={{ pathname: '/coupons' }} />;
    // }

    return (
        <Page>
            <Title>Crear usuario</Title>
            <Form onSubmit={formHandler}>
                {error && <Error error={errorMessage} />}
                <fieldset
                    disabled={loading}
                    aria-busy={loading}
                >
                    <label htmlFor="email">
                        Email
                        <input
                            type="email"
                            id="email"
                            name="email"
                            // placeholder="Email"
                            required
                            value={formData.email.toLowerCase()}
                            onChange={inputHandler}
                        />
                    </label>
                    <label htmlFor="name">
                        Nombre
                        <input
                            type="text"
                            id="name"
                            name="name"
                            required
                            value={formData.name}
                            onChange={inputHandler}
                        />
                    </label>
                    <Button type="submit">Crear usuario</Button>
                </fieldset>
            </Form>

            <Modal visible={visible && data} hide={toggle}>
                {
                    data && <p>{data.activeUserAndOrderDisable.message}</p>
                }
            </Modal>
        </Page>
    );
}

export default CreateUser;
