import styled from 'styled-components'

const Card = styled.div`
  border: 0.5px solid #bfbfdc;
  padding: 20px;
  width: 100%;
  h2 {
    color: #565a6e;
    margin-bottom: 2rem;
    font-size: 1.5rem;
  }
`

export { Card }
