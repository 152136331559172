import styled from 'styled-components';

const Container = styled.div`
    width: 100%;
    color: #565a6e;
    border: 0.5px solid #bfbfdc;
    padding: 20px;
    font-size: 1.5rem;
    line-height: 1.5;
    box-sizing: border-box;
    label {
        display: block;
    }
    &.mb{
        margin-bottom: 2rem;
    }
`;
export { Container };
