import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { useParams } from "react-router-dom";
import { Page, Title } from '../../components/styles';
import UsuariosByType from './actions/usuarios-by-type';


function User() {

    return (
        <Page>
            <Title>Descargar información:</Title>
            <p>No se toman en cuenta los usuarios equipo delfino</p>
            <br />
            <UsuariosByType />
        </Page>
    );
}

export default User;
