const env = process.env.NODE_ENV || 'development';
const formatter = ({ graphQLErrors, networkError }, setError) => {
    let msg;
    if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path, extensions }) => {
            msg = message;
            if (env === 'development') {
                console.error(message);
            }
            return msg;
        });
    }
    if (networkError) {
        console.log('conexion');
        msg = 'Error de conexión o servidor';
    }
    setError(msg);
};
module.exports = formatter;
