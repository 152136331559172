import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import { Page, Title, Card, Button } from '../components/styles';
import { Table, Pagination, Error, Spinner } from '../components';
import useParams from '../hooks/useParams';
import formatDataTable from '../utils/format-data-table';
const columns = ['Título', 'Mensaje', 'Tipo'];

const GET_NOTIFICATIONS_QUERY = gql`
    query GET_NOTIFICATIONS_QUERY($offset: Int) {
        getNotifications(offset: $offset) {
            count
            notifications {
                title
                message
                type
            }
        }
    }
`;

function Notifications() {
    const urlParams = useParams();
    const page = parseInt(urlParams.get('page')) || 1;
    const itemsPerPage = 10;
    const { loading, error, data } = useQuery(GET_NOTIFICATIONS_QUERY, {
        variables: { offset: page * itemsPerPage - itemsPerPage },
    });

    if (loading) {
        return <Spinner />;
    }

    if (error) {
        return <Error error={error} />;
    }

    return (
        <Page>
            <Title>Notificaciones</Title>
            <Button
                as={Link}
                style={{
                    textDecoration: 'none',
                    display: 'block',
                    textAlign: 'center',
                    marginBottom: '20px',
                    border: '1px solid #979797',
                }}
                to="/create-notification"
            >
                Crear Notificacion
            </Button>
            <Card>
                <Table
                    columns={columns}
                    rows={formatDataTable(
                        data?.getNotifications?.notifications
                    )}
                />
                <Pagination
                    page={page}
                    count={data?.getNotifications?.count}
                    itemsPerPage={itemsPerPage}
                    pathname="notifications"
                />
            </Card>
        </Page>
    );
}

export { GET_NOTIFICATIONS_QUERY };
export default Notifications;
