import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import { Form, Button } from '../../../components/styles';
import { Modal, Error } from '../../../components';
import useVisible from '../../../hooks/useModal';

const REQUEST_PASSWORD_MUTATION = gql`
    mutation REQUEST_PASSWORD_MUTATION($ID: ID) {
        requestChangePassword(ID: $ID)
    }
`;

function RequestPassword() {
    const { id } = useParams();
    const [visible, toggle] = useVisible(true);
    const [requestPasswordMutation, { loading, error, data }] = useMutation(
        REQUEST_PASSWORD_MUTATION
    );

    const formHandler = (e) => {
        e.preventDefault();
        if (window.confirm("Confirmar solicitar cambio de contraseña")) {
            requestPasswordMutation({ variables: { ID: id } });
        }
    };
    if (error) {
        console.log(error);
    }
    return (
        <>
            <Form style={{ marginTop: '2rem' }} onSubmit={formHandler}>
                {error && <Error error={error} />}
                <fieldset disabled={loading} aria-busy={loading}>
                    <label htmlFor="password">Solicitar nueva contraseña</label>
                    <Button type="submit">Solicitar</Button>
                </fieldset>
            </Form>
            <Modal visible={visible && data} hide={toggle}>
                <p>Se ha solicitado una nueva contraseña</p>
            </Modal>
        </>
    );
}

export default RequestPassword;
