import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { Redirect } from 'react-router-dom';
import { Page, Form, Button, Title } from '../components/styles';
import { SearchPost } from '../components';
import { GET_NOTIFICATIONS_QUERY } from './notifications';
import { Error } from '../components';
const CREATE_NOTIFICATION_MUTATION = gql`
    mutation CREATE_NOTIFICATION_MUTATION(
        $title: String!
        $message: String!
        $target: Int!
        $type: String!
        $data: String
        $url: String!
    ) {
        createNotificationAndBroadcast(
            title: $title
            message: $message
            target: $target
            type: $type
            data: $data
            url: $url
        ) {
            notification {
                title
                message
            }
        }
    }
`;

const INITIAL_STATE = {
    title: '',
    message: '',
    target: 1,
    type: 'POST',
    data: '{}',
    search: '',
    url: ''
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'title':
            return { ...state, title: action.value };
        case 'message':
            return { ...state, message: action.value };
        case 'target':
            return { ...state, target: parseInt(action.value) };
        case 'type':
            return { ...state, type: action.value };
        case 'url':
            return { ...state, url: action.value };
        case 'data':
            return {
                ...state,
                data: JSON.stringify({
                    type: 'viewPost',
                    data: action.value.toString(),
                }),
            };
        case 'search':
            return { ...state, search: action.value };
        case 'clear_state':
            return { ...INITIAL_STATE };
        default:
            return state;
    }
};

const isValid = (formData)=>{
    const { title, message, data, search, url } = formData;

    if(!title || !message || !data || !search || !url){
        return false;
    }

    return true;

};

function CreateNotification() {
    const [formData, dispatch] = React.useReducer(reducer, INITIAL_STATE);

    const [createNotificationMutation, { loading, error, data }] = useMutation(
        CREATE_NOTIFICATION_MUTATION,
        {
            awaitRefetchQueries: true,
            refetchQueries: [
                { query: GET_NOTIFICATIONS_QUERY, variables: { offset: 0 } },
            ],
        }
    );

    const formHandler = (e) => {
        e.preventDefault();
        if (window.confirm("Confirmar envio de  notificación, los datos son correctos?")) {
            createNotificationMutation({ variables: formData });

        }
    };

    const inputHandler = (e) => {
        const { name, value } = e.target;
        dispatch({ type: name, value });
    };
    const postHandler = (value) => {
        dispatch({ type: 'data', value });
        if (value) {
            dispatch({ type: 'type', value: 'POST' });
        } else {
            dispatch({ type: 'type', value: 'TEXT' });
        }
    };

    const activeSubmit = isValid(formData);

    if (data) {
        return <Redirect to={{ pathname: '/notifications' }} />;
    }
    console.log(' ');
    console.log(' ');
    console.log(' ');
    console.log(' ');
    console.log(formData, 'formData');
    console.log(' ');
    console.log(' ');
    console.log(' ');
    console.log(' ');
    return (
        <Page>
            <Title>Crear Notificacion</Title>
            <Form onSubmit={formHandler}>
                {error && <Error error={error} />}
                <fieldset disabled={loading} aria-busy={loading}>
                    <label htmlFor="title">
                        Título
                        <input
                            type="text"
                            id="title"
                            name="title"
                            placeholder="Titulo"
                            required
                            value={formData.title}
                            onChange={inputHandler}
                        />
                    </label>
                    <label htmlFor="message">
                        Mensaje
                        <input
                            type="text"
                            id="message"
                            name="message"
                            placeholder="Mensaje"
                            required
                            value={formData.message}
                            onChange={inputHandler}
                        />
                    </label>
                    <label htmlFor="title">
                        Link notificación
                        <input
                            type="url"
                            id="url"
                            name="url"
                            placeholder="https://delfino.cr/"
                            required
                            value={formData.url}
                            onChange={inputHandler}
                        />
                    </label>
                    {/* <label htmlFor="type">
                        Tipo de notificación
                        <select
                            name="type"
                            required
                            value={formData.type}
                            onChange={inputHandler}
                        > */}
                            {/* <option value={'TEXT'}>TEXTO</option> */}
                            {/* <option value={'POST'}>POST</option>
                        </select>
                    </label> */}
                    {formData.type === 'POST' ? (
                        <SearchPost
                            inputHandler={inputHandler}
                            search={formData.search}
                            postHandler={postHandler}
                        />
                    ) : null}
                    {/* <label htmlFor="target">
                        Público
                        <select
                            name="target"
                            required
                            value={formData.target}
                            onChange={inputHandler}
                        >
                            <option value={1}>Logueado</option>
                            <option value={0}>Invitado</option>
                        </select>
                    </label> */}
                    <br />
                    { activeSubmit && <Button type="submit">Crear Notificacion</Button>}
                    
                </fieldset>
            </Form>
        </Page>
    );
}

export default CreateNotification;
