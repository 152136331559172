import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { Table } from './u-table';
import { Dropdown } from '../components/styles';
import formatDataTable from '../utils/format-data-table';
const columns = [
    'ID Usuario',
    'Nombre',
    'Correo',
    'Suscripción',
    'Estatus',
    'ID Suscripción',
];
const UPDATE_USER = gql`
    mutation UPDATE_USER($ID: Int, $status: Int) {
        updateUserInfo(ID: $ID, status: $status) {
            name
            email
        }
    }
`;
const UserTable = ({ rows, onClick }) => {
    const [updateUser, { error, data }] = useMutation(UPDATE_USER);
    const [users, setUsers] = React.useState(rows);
    const onChange = (e, index) => {
        const newUsers = [...users];
        e.preventDefault();
        let status;
        switch (e.target.value) {
            case 'Inactivo':
                status = 0;
                break;
            case 'Activo':
                status = 1;
                break;
            case 'Cobro':
                status = 2;
                break;
            case 'Hold':
                status = 3;
                break;
            case 'Cancelación':
                status = 4;
                break;
            default:
                status = 0;
                break;
        }
        newUsers[index].subscription.status = status;
        updateUser({
            variables: {
                ID: newUsers[index].ID,
                status,
            },
        });
        setUsers(newUsers);
    };
    if (error) {
        console.error(error);
    }
    if (data) {
        console.log('Actualización');
    }
    const addDropdownToRows = (rows, onChange) => {
        return rows.map((row, index) => {
            let newRow = [...row];
            newRow[4] = (
                <Dropdown
                    value={newRow[4]}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) => onChange(e, index)}
                >
                    <option>Activo</option>
                    <option>Inactivo</option>
                    <option>Cobro</option>
                    <option>Hold</option>
                    <option>Cancelación</option>
                    <option>N/A</option>
                </Dropdown>
            );
            return [...newRow];
        });
    };
    const formatRows = () => {
        const Users = users.map((user) => {
            let newUser = {
                ...user,
            };
            if (newUser.subscription) {
                switch (newUser.subscription.status) {
                    case 0:
                        newUser.status = 'Inactivo';
                        break;
                    case 1:
                        newUser.status = 'Activo';
                        break;
                    case 2:
                        newUser.status = 'Cobro';
                        break;
                    case 3:
                        newUser.status = 'Hold';
                        break;
                    case 4:
                        newUser.status = 'Cancelación';
                        break;
                    default:
                        newUser.status = 'N/A';
                }
                newUser.subscription_id =
                    newUser.subscription.subscription_token;
                newUser.subscription = newUser.subscription.title;
            }
            return newUser;
        });
        const cleanRows = formatDataTable(Users);
        return cleanRows;

        // return addDropdownToRows(cleanRows, onChange, setUsers);
    };
    return <Table columns={columns} rows={formatRows()} onClick={onClick} />;
};
export { UserTable };
