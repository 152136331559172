import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import { Form, Button } from '../../../components/styles';
import { Modal, Error } from '../../../components';
import useVisible from '../../../hooks/useModal';

const UPDATE_PASSWORD_MUTATION = gql`
    mutation UPDATE_PASSWORD_MUTATION($ID: Int!){
        fnUpdatePassword(ID: $ID){
            message
        }
    }
`;

function UpdatePassword() {
    const { id } = useParams();
    const [visible, toggle] = useVisible(true);
    const [updatePasswordMutation, { loading, error, data }] = useMutation(
        UPDATE_PASSWORD_MUTATION
    );

    const formHandler = (e) => {
        e.preventDefault();
        if (window.confirm("Confirmar actualizar la contraseña a bienvenido-delfino")) {
            updatePasswordMutation({ variables: { ID: parseInt(id, 10) } });
        }
    };
    if (error) {
        console.log(error);
    }
    return (
        <>
            <Form style={{ marginTop: '2rem' }} onSubmit={formHandler}>
                {error && <Error error={error} />}
                <fieldset disabled={loading} aria-busy={loading}>
                    <label htmlFor="password">Actualizar contraseña standar</label>
                    <Button type="submit">Actualizar</Button>
                </fieldset>
            </Form>
            <Modal visible={visible && data} hide={toggle}>
                <p>Se ha Actualizado la contraseña a bienvenido-delfino</p>
            </Modal>
        </>
    );
}

export default UpdatePassword;
