import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import { Page, Title, Card, Button } from '../components/styles';
import { Pagination, Error, Spinner, CouponTable } from '../components';
import useParams from '../hooks/useParams';
import formatDataTable from '../utils/format-data-table';
const columns = ['Codigo', 'Descuento', 'Canjeados', 'Modo', 'Descripción'];

const GET_COUPONS_QUERY = gql`
    query GET_COUPONS_QUERY($offset: Int) {
        coupons(offset: $offset) {
            count
            values {
                name
                discount
                exchange
                display
                description
            }
        }
    }
`;

function Coupons() {
    const urlParams = useParams();
    const page = parseInt(urlParams.get('page')) || 1;
    const itemsPerPage = 10;
    const { loading, error, data } = useQuery(GET_COUPONS_QUERY, {
        variables: { offset: page * itemsPerPage - itemsPerPage },
    });

    if (loading) {
        return <Spinner />;
    }

    if (error) {
        return <Error error={error} />;
    }
    return (
        <Page>
            <Title>Cupones</Title>
            <Button
                as={Link}
                style={{
                    textDecoration: 'none',
                    display: 'block',
                    textAlign: 'center',
                    marginBottom: '20px',
                    border: '1px solid #979797',
                }}
                to="/create-coupon"
            >
                Crear Cupon
            </Button>
            <Card>
                <CouponTable
                    columns={columns}
                    rows={formatDataTable(data?.coupons.values)}
                />
                <Pagination
                    page={page}
                    count={data?.coupons.count}
                    itemsPerPage={itemsPerPage}
                    pathname="coupons"
                />
            </Card>
        </Page>
    );
}

export { GET_COUPONS_QUERY };
export default Coupons;
