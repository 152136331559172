import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Table } from './table';
import { Dropdown, Option } from './styles';
import gql from 'graphql-tag';

const UPDATE_COUPON = gql`
    mutation UPDATE_COUPON($name: String!, $active: Int) {
        updateCoupon(name: $name, active: $active) {
            name
            discount
            exchange
            display
            description
        }
    }
`;
const CouponTable = ({ columns, rows }) => {
    const [updateCoupon, { error, data }] = useMutation(UPDATE_COUPON);
    const onChangeDropdown = (e, name) => {
        updateCoupon({
            variables: {
                name,
                active: e.target.value === 'ACTIVO' ? 1 : 0,
            },
        });
    };
    if (error) {
        console.log(error);
    }
    if (data) {
        console.log(data.updateCoupon);
    }
    return (
        <Table
            columns={columns}
            rows={addDropdowToRows(rows, onChangeDropdown)}
        />
    );
};
const addDropdowToRows = (rows, onChange) => {
    return rows.map((row) => {
        let newRow = [...row];
        newRow[3] = (
            <Dropdown onChange={(e) => onChange(e, row[0])}>
                {newRow[3] === 'ACTIVO' ? (
                    <>
                        <Option>ACTIVO</Option>
                        <Option>INACTIVO</Option>
                    </>
                ) : (
                    <>
                        <Option>INACTIVO</Option>
                        <Option>ACTIVO</Option>
                    </>
                )}
            </Dropdown>
        );
        return [...newRow];
    });
};
export { CouponTable };
