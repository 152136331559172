import styled from 'styled-components';
const Dropdown = styled.select`
    background-color: ${(props) => props.backgroundColor || '#607EFB'};
    padding: 1rem 3rem;
    color: #ffffff;
    font-size: 1rem;
    border: 0px;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
`;
const Option = styled.option`
    background-color: ${(props) => props.backgroundColor || '#607EFB'};
    color: #ffffff;
    border: 0px;
    width: 200px !important;
    heigth: 100px;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
`;
export { Dropdown, Option };
