import React from 'react'

const AuthContext = React.createContext({
  tokens: {},
  login: () => {},
  logout: () => {},
})
AuthContext.displayName = 'AuthContext'

function AuthProvider(props) {
  const token = localStorage.getItem('x-token')
  const refreshToken = localStorage.getItem('x-refresh-token')
  const [tokens, setTokens] = React.useState({ token, refreshToken })

  const login = (data) => {
    if (data?.token) {
      localStorage.setItem('x-token', data.token)
      localStorage.setItem('x-refresh-token', data.refreshToken)
    }
    setTokens(data)
  }

  const logout = () => {
    localStorage.clear()
    setTokens(null)
  }

  return <AuthContext.Provider value={{ tokens, login, logout }} {...props} />
}

function useAuth() {
  return React.useContext(AuthContext)
}

export { AuthProvider, useAuth }
