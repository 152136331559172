import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../context/auth';
import useVisible from '../hooks/useModal';
import { Modal } from './modal';
import { Logo } from './logo';
import { Button } from './styles/button';
import packageJson from '../../package.json';
const StyledNavbar = styled.nav`
    width: 15%;
    box-shadow: 6px 0 5px 0 rgba(0, 0, 0, 0.1);
    padding-top: 35px;
`;

const ContainerOption = styled(NavLink)`
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    color: #000;
    height: 60px;
    padding: 3% 10%;
    color: #565a6e;
    &.active {
        background-color: #e8edf650;
        border-right: 6px solid #b3ceff;
    }
`;
const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    color: #000;
    height: 60px;
    padding: 3% 10%;
    color: #565a6e;
    &.active {
        background-color: #e8edf650;
        border-right: 6px solid #b3ceff;
    }
`;
const P = styled.p`
    margin-left: 1.4rem;
    font-size: 16px;
`;

function Navbar() {
    const [visible, toggle] = useVisible();
    const { logout } = useAuth();

    const onClickHide = () => {
        toggle();
    };

    const onClickLogout = () => {
        logout();
    };

    return (
        <StyledNavbar>
            <Logo style={{ marginBottom: '35px' }} />
            <ContainerOption to="/" exact={true}>
                <i className="fas fa-chart-bar fa-lg" />
                <P>Indicadores</P>
            </ContainerOption>
            <ContainerOption to="/users">
                <i className="fas fa-users fa-lg" />
                <P>Soporte</P>
            </ContainerOption>
            <ContainerOption to="/create">
                <i className="fas fa-user fa-lg" />
                <P>Create</P>
            </ContainerOption>
            <ContainerOption to="/coupons">
                <i className="fas fa-ticket-alt fa-lg" />
                <P>Cupones</P>
            </ContainerOption>
            <ContainerOption to="/notifications">
                <i className="fas fa-bell fa-lg" />
                <P>Notificaciones</P>
            </ContainerOption>
            <ContainerOption to="/csv">
                <i className="fas fa-file fa-lg" />
                <P>CSV</P>
            </ContainerOption>
            <ContainerOption to="/cache">
                <i className="fas fa-eraser fa-lg" />
                <P>Cache</P>
            </ContainerOption>
            <Container>
                <P>Versión {packageJson.version}</P>
            </Container>
            <Button
                style={{ position: 'absolute', bottom: 0, width: '15%' }}
                onClick={onClickHide}
            >
                <i className="fas fa-sign-out-alt fa-lg" />
            </Button>
            <Modal visible={visible} hide={toggle}>
                <p>Esta seguro de cerrar session?</p>
                <Button style={{ width: '100%' }} onClick={onClickLogout}>
                    Aceptar
                </Button>
            </Modal>
        </StyledNavbar>
    );
}

export { Navbar };
