import React from 'react';
import { Table } from './table';

const PostTable = ({ columns, rows, onClick }) => {
    return (
        <Table
            columns={columns}
            rows={addImageToRows(rows)}
            onClick={onClick}
        />
    );
};
const addImageToRows = (rows) => {
    return rows.map((post) => {
        const image = (
            <img
                src={post.image.thumbnail ? post.image.thumbnail : ''}
                alt={'Thumbnail'}
                width={100}
                height={100}
            ></img>
        );
        const date = new Date(Number.parseInt(post.created));
        const formatDate =
            date.getDate().toString() +
            '/' +
            (date.getMonth() + 1) +
            '/' +
            date.getFullYear();
        return [image, post.ID, post.slug, formatDate];
    });
};
export { PostTable };
