import React from 'react';
import styled from 'styled-components';
import { uniqueId } from 'lodash/util';
import { Container, Header, Title, Button } from '../../../components/styles/';

const P = styled.p`
    color: #565a6e;
    font-size: 14px;
`;

const Row = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    h4,p{
        margin: 0;
    }
    p{
        margin-left:10px;
    }
`;

const Information = ({ data, status, setModeEdit }) => {
    let userData = [];
    let statusText;
    let interests = data.interests ? data.interests.join(', ') : '';

    // Data user
    userData.push({ data: 'ID', value: data.ID });
    userData.push({ data: 'Nombre', value: data.name });
    userData.push({ data: 'Email', value: data.email });
    userData.push({ data: 'Phone', value: `${data.codeCountryPhone || ''}${data.phone || ''}`});
    userData.push({ data: 'Desea recibir notificaciones por WhatsApp', value: data.whatsappMsg === '1' ? 'Si': 'No'});
    userData.push({ data: 'Temas interes', value: interests});

    // TODO create helper fn
    switch (status) {
        case 0:
            statusText = 'Sin suscripción';
            break;
        case 1:
            statusText = 'Activo';
            break;
        case 2:
            statusText = 'En cobro';
            break;
        case 3:
            statusText = 'Moroso';
            break;
        case 4:
            statusText = 'En cancelación';
            break;
        case 6:
            statusText = 'Usuario de Plan familiar';
            break;
        default:
            statusText = 'Sin definir';
    }
    userData.push({ data: 'Status', value: statusText });

    return (
        <>
            <Container className="mb">
                {userData.map(({ data, value }) => {
                    return (
                        <Row key={uniqueId()}>
                            <Header>{data}:</Header>
                            <P>{value}</P>
                        </Row>
                    )
                })}
                <Row>
                    <Button style={{ width: '200px' }} onClick={(e)=> setModeEdit(true)}>
                        Editar
                    </Button>
                </Row>
            </Container>
        </>
    );
};
export default Information;
