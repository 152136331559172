import React from 'react'
import styled, { keyframes } from 'styled-components'
import { Page } from './styles/page'

const rotate = keyframes`
  0% {
      transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
        transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
`

const StyledSpinner = styled.div`
  position: relative;
  text-align: center;
  top: 50%;
  opacity: 1;
  transition: opacity linear 0.1s;
  &::before {
    border: solid 5px #eee;
    border-bottom-color: #607efb;
    content: '';
    border-radius: 50%;
    width: 60px;
    height: 60px;
    opacity: inherit;
    position: absolute;
    animation: 2s linear infinite ${rotate};
    transform: translate3d(-50%, -50%, 0);
    transform-origin: center;
    will-change: transform;
  }
`

function Spinner() {
  return (
    <Page>
      <StyledSpinner role='alert' aria-live='assertive' />{' '}
    </Page>
  )
}

export { Spinner }
