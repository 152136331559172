import React from 'react';
import styled from 'styled-components';
import { uniqueId } from 'lodash/util';
import { Container, Header, Title } from '../../components/styles/';

const P = styled.p`
    color: #565a6e;
    font-size: 14px;
`;
const Row = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    h4,p{
        margin: 0;
    }
    p{
        margin-left:10px;
    }
`;

const Suscripcion = ({data}) => {
    const { description, card_token, price, subscription_token, activationDate, nextPayment, title, card, brand, platform } = data;

    let userSuscription = [];
    let plataforma = 'Indefinido';
    let dL = description.toLowerCase().trim();
    let cardtoken = card_token.toLowerCase().trim();
    let subscriptionToken = subscription_token.toLowerCase().trim();

    // plataforma Suscripción
    // SINPE, Greenpay, Klap, Equipo Delfino
    if(subscriptionToken.toLowerCase() === 'sinpe' ){
        plataforma = 'Sinpe';
    }else{
        if(dL.includes('equipo delfino')){
            plataforma = 'Equipo Delfino';
        }else if(platform === 'onvo'){
            plataforma = 'Onvo';
        }else if(cardtoken.length > 10){
            plataforma = 'Greenpay';
        }
    }


    // Data user
    userSuscription.push({ data: 'Title', value: title});
    userSuscription.push({ data: 'Description', value: description});
    userSuscription.push({ data: 'Plataforma pago', value: plataforma});
    userSuscription.push({ data: 'Número de tarjeta', value: `**** **** **** ${card} ${brand}`});
    userSuscription.push({ data: 'Precio', value: `$${price}`});
    userSuscription.push({ data: 'Creada', value: activationDate});
    userSuscription.push({ data: 'Siguiente pago', value: nextPayment});

    return (
        <>
            <Container className="mb">
                <Title>Suscripción</Title>
                {userSuscription.map(({data, value})=>{
                    return(
                        <Row key={uniqueId()}>
                            <Header>{data}:</Header>
                            {
                                data === 'Description' ? <P contentEditable='true' dangerouslySetInnerHTML={{ __html: value }}></P> : <P>{value}</P>
                            }
                        </Row>
                    )
                })}
            </Container>

        </>
    );
};
export default Suscripcion;
