import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import { Form, Button } from '../../../components/styles';
import { Modal, Error } from '../../../components';
import useVisible from '../../../hooks/useModal';

const CANCEL_SUSCRIPTION_MUTATION = gql`
    mutation Cancel_User_Subscription($ID: Int!){
        cancelUserSubscription(ID: $ID) {
            message
        }
    }
`;

function CancelSuscription() {
    const { id } = useParams();
    const [visible, toggle] = useVisible(true);
    const [requestPasswordMutation, { loading, error, data }] = useMutation(
        CANCEL_SUSCRIPTION_MUTATION
    );

    const formHandler = (e) => {
        e.preventDefault();
        if (window.confirm("Confirmar Cancelar suscripción")) {
            requestPasswordMutation({ variables: { ID: parseInt(id, 10) } });

        }
    };
    if (error) {
        console.log(error);
    }

    return (
        <>
            <Form style={{ marginTop: '2rem' }} onSubmit={formHandler}>
                {error && <Error error={error} />}
                <fieldset disabled={loading} aria-busy={loading}>
                    <label htmlFor="password">Cancelar suscripción</label>
                    <Button type="submit"  backgroundColor="red">Cancelar</Button>
                </fieldset>
            </Form>
            <Modal visible={visible && data} hide={toggle}>
                {
                    data && <p>{data.cancelUserSubscription.message}</p>
                }
            </Modal>
        </>
    );
}

export default CancelSuscription;
