import React from 'react';
import { Redirect } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useAuth } from '../context/auth';
import { Center, Form, Button } from '../components/styles';
import { Error, Logo } from '../components';
import errorFormatter from '../utils/error-formatter';
const LOGIN_ADMIN_MUTATION = gql`
    mutation LOGIN_ADMIN_MUTATION($email: String!, $password: String!) {
        loginAdmin(email: $email, password: $password) {
            tokenData {
                token
                refreshToken
            }
        }
    }
`;

function SignIn(props) {
    const [formData, setFormData] = React.useState({
        email: '',
        password: '',
    });
    const [errorMessage, setError] = React.useState(null);
    const [loginMutation, { loading, error, data }] = useMutation(
        LOGIN_ADMIN_MUTATION,
        {
            onError: (error) => errorFormatter(error, setError),
        }
    );
    const { login, tokens } = useAuth();
    const referer = props?.location?.state?.referer || '/';

    const formHandler = (e) => {
        e.preventDefault();
        try {
            loginMutation({ variables: formData });
        } catch (error) {
            console.log(error);
        }
    };
    if (data?.loginAdmin) {
        login(data.loginAdmin.tokenData);
    }
    const inputHandler = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    if (tokens?.token) {
        return <Redirect to={referer} />;
    }
    return (
        <Center>
            <Form onSubmit={formHandler}>
                <Logo style={{ marginBottom: '35px' }} />
                <fieldset disabled={loading} aria-busy={loading}>
                    {error && <Error error={errorMessage} />}
                    <label htmlFor="email">
                        Correo
                        <input
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Correo"
                            required
                            value={formData.email}
                            onChange={inputHandler}
                        />
                    </label>
                    <label htmlFor="password">
                        Contraseña
                        <input
                            type="password"
                            id="password"
                            name="password"
                            placeholder="Contraseña"
                            required
                            value={formData.password}
                            onChange={inputHandler}
                        />
                    </label>
                    <Button type="submit">Submit</Button>
                </fieldset>
            </Form>
        </Center>
    );
}

export { LOGIN_ADMIN_MUTATION };
export default SignIn;
