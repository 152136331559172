import styled, { keyframes } from 'styled-components'

const loading = keyframes`
  from {
    background-position: 0 0;
  }

  to {
    background-position: 100% 100%;
  }
`

const Form = styled.form`
  width: 100%;
  color: #565a6e;
  border: 0.5px solid #bfbfdc;
  padding: 20px;
  font-size: 1.5rem;
  line-height: 1.5;
  box-sizing: border-box;
  label {
    display: block;
    margin-bottom: 1rem;
  }
  input,
  textarea,
  select {
    display: block;
    width: 97.5%;
    padding: 1rem;
    font-size: 1rem;
    border: 1px solid #eeeeee;
    border-radius: 0.2rem;
    box-shadow: 0 0 1px 1px #f9f9f9;
    margin-top: 0.7rem;
    margin-bottom: 0.7rem;
    &:focus {
      outline: 0;
      border-color: #bfbfdc;
    }
  }
  button {
    width: 100%;
  }
  fieldset {
    border: 0;
    padding: 0;
    &[disabled] {
      opacity: 0.5;
    }
    &::before {
      margin-bottom: 20px;
      height: 10px;
      content: '';
      display: block;
      background-color: #ffffff;
      background-image: linear-gradient(to right, #ffffff 0%, #d7e1ec 74%);
    }
    &[aria-busy='true']::before {
      background-size: 50% auto;
      animation: ${loading} 0.5s linear infinite;
    }
  }
`

export { Form }
