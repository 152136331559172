import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import styled from 'styled-components';
import { uniqueId } from 'lodash/util';
import { Error, Spinner } from '../../components';
import { Container, Header, Title } from '../../components/styles/';

const P = styled.p`
    color: #565a6e;
    font-size: 14px;
`;
const Row = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    h4,p{
        margin: 0;
    }
    p{
        margin-left:10px;
    }
`;

const GET_USER_FACTURA = gql`
    query GET_USER_FACTURA($ID: Int!) {
        myInvoiceInfo(ID: $ID) {
            identificationType
            identificationValue
            name
            email
        }
    }
`;

function User({ userId }) {
    const { loading, error, data } = useQuery(GET_USER_FACTURA, {
        variables: {
            ID: parseInt(userId, 10)
        },
    });

    if (loading) {
        return <Spinner />;
    }

    if (error) {
        return <Error error={error} />;
    }

    const showInfo = (infoFact) => {
        let userData = [];

        if (!infoFact || !infoFact.myInvoiceInfo) {
            return (
                <Row key={uniqueId()}>
                    <P>Sin información</P>
                </Row>
            )
        }

        const info = infoFact.myInvoiceInfo;
        const { identificationType, identificationValue, name, email } = info;

        userData.push({ data: 'Tipo de Identificación', value: identificationType });
        userData.push({ data: 'Cédula', value: identificationValue });
        userData.push({ data: 'Nombre', value: name });
        userData.push({ data: 'Correo Electrónico', value: email });


        return userData.map(({ data, value }) => {
            return (
                <Row key={uniqueId()}>
                    <Header>{data}:</Header>
                    <P>{value}</P>
                </Row>
            )
        })
    };

    return (
        <Container className="mb">
            <Title>Datos facturación</Title>
            { showInfo(data)}
        </Container>
    );
}

export default User;