import React from 'react';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Redirect } from 'react-router-dom';
import { Page, Form, Button, Title } from '../components/styles';
import { GET_COUPONS_QUERY } from './coupons';
import { Spinner, Error } from '../components';
import errorFormatter from '../utils/error-formatter';
const GET_SUBSCRIPTIONS_QUERY = gql`
    query GET_SUBSCRIPTIONS {
        subscriptions {
            ID
            title
            initial
            price
        }
    }
`;

const CREATE_COUPON_MUTATION = gql`
    mutation CREATE_COUPON_MUTATION(
        $name: String!
        $discount: Float!
        $subscription_id: Int!
        $type: Int!
        $active: Int!
        $description: String!
    ) {
        createCoupon(
            name: $name
            discount: $discount
            subscription_id: $subscription_id
            type: $type
            active: $active
            description: $description
        ) {
            name
            discount
            exchange
            ID
        }
    }
`;

const INITIAL_STATE = {
    name: '',
    discount: 0,
    subscription_id: 1,
    type: 1,
    active: 1,
    description: '',
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'name':
            return Object.assign({}, state, {
                name: action.value.toUpperCase(),
            });
        case 'discount':
            return Object.assign({}, state, {
                discount: parseFloat(action.value),
            });
        case 'subscription_id':
            return Object.assign({}, state, {
                subscription_id: parseFloat(action.value),
            });
        case 'type':
            return Object.assign({}, state, { type: parseInt(action.value) });
        case 'active':
            return Object.assign({}, state, { active: parseInt(action.value) });
        case 'description':
            return Object.assign({}, state, { description: action.value });
        case 'clear_state':
            return Object.assign({}, state, INITIAL_STATE);
        default:
            return state;
    }
};

function CreateCoupon() {
    const [formData, dispatch] = React.useReducer(reducer, INITIAL_STATE);
    const subscriptions = useQuery(GET_SUBSCRIPTIONS_QUERY);
    const [errorMessage, setError] = React.useState(null);
    const [createCouponMutation, createCouponData] = useMutation(
        CREATE_COUPON_MUTATION,
        {
            onError: (error) => errorFormatter(error, setError),
            awaitRefetchQueries: true,
            refetchQueries: [
                { query: GET_COUPONS_QUERY, variables: { offset: 0 } },
            ],
        }
    );

    const formHandler = (e) => {
        e.preventDefault();
        createCouponMutation({ variables: formData });
    };

    const inputHandler = (e) => {
        const { type, name, value } = e.target;
        const val = type === 'number' ? parseFloat(value) : value;
        dispatch({ type: name, value: val });
    };

    const renderOptionsSubscription = () =>
        subscriptions.data.subscriptions
            .filter((sub) => {
                const ids = [1, 2];
                if (ids.includes(sub.ID)) {
                    return true;
                }
                return false;
            })
            .map(({ ID, title }) => (
                <option value={ID} key={ID}>
                    {title}
                </option>
            ));

    if (subscriptions.loading) {
        return <Spinner />;
    }

    if (subscriptions.error) {
        return <Error error={subscriptions.error} />;
    }

    if (createCouponData.data) {
        return <Redirect to={{ pathname: '/coupons' }} />;
    }

    return (
        <Page>
            <Title>Crear Cupon</Title>
            <Form onSubmit={formHandler}>
                {createCouponData.error && <Error error={errorMessage} />}
                <fieldset
                    disabled={createCouponData.loading}
                    aria-busy={createCouponData.loading}
                >
                    <label htmlFor="name">
                        Codigo
                        <input
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Nombre"
                            required
                            value={formData.name.toUpperCase()}
                            onChange={inputHandler}
                        />
                    </label>
                    <label htmlFor="discount">
                        Descuento
                        <input
                            type="number"
                            id="discount"
                            name="discount"
                            placeholder="Descuento"
                            required
                            value={formData.discount}
                            onChange={inputHandler}
                        />
                    </label>
                    <label htmlFor="description">
                        Descripción
                        <input
                            type="text"
                            id="description"
                            name="description"
                            placeholder="Descripción"
                            required
                            value={formData.description}
                            onChange={inputHandler}
                        />
                    </label>
                    <label htmlFor="subscription_id">
                        Subscripción
                        <select
                            name="subscription_id"
                            required
                            value={formData.subscription_id}
                            onChange={inputHandler}
                        >
                            {renderOptionsSubscription()}
                        </select>
                    </label>
                    <label htmlFor="type">
                        Uso
                        <select
                            name="type"
                            required
                            value={formData.type}
                            onChange={inputHandler}
                        >
                            <option value={1}>Múltiple</option>
                            <option value={0}>Único</option>
                        </select>
                    </label>
                    <label htmlFor="active">
                        Estados
                        <select
                            name="active"
                            required
                            value={formData.active}
                            onChange={inputHandler}
                        >
                            <option value={0}>Inactivo</option>
                            <option value={1}>Activo</option>
                        </select>
                    </label>
                    <Button type="submit">Crear Cupon</Button>
                </fieldset>
            </Form>
        </Page>
    );
}

export default CreateCoupon;
