import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { ProgressRoute as Route, PrivateRoute } from './components/routes';
import { ApolloProvider } from '@apollo/react-hooks';
import { AuthProvider } from './context/auth';
import { ContainerPage } from './components/styles';
import { Spinner } from './components';
import client from './utils/client';
import Login from './screens/login';
import CreateCoupon from './screens/create-coupon';
import CreateNotification from './screens/create-notification';
import Users from './screens/users';
import CSV from './screens/csv';
import CACHE from './screens/cache';
import User from './screens/user';
import Create from './screens/create';
//import Dashboard from './screens/dashboard';
const Notifications = React.lazy(() => import('./screens/notifications'));
const Dashboard = React.lazy(() => import('./screens/dashboard'));
const Coupons = React.lazy(() => import('./screens/coupons'));

function App() {
    return (
        <ApolloProvider client={client}>
            <AuthProvider>
                <React.Suspense
                    fallback={
                        <ContainerPage>
                            <Spinner />
                        </ContainerPage>
                    }
                >
                    <Router>
                        <Switch>
                            <Route exact path="/login" component={Login} />
                            <PrivateRoute
                                exact
                                path="/csv"
                                component={CSV}
                            />
                            <PrivateRoute
                                exact
                                path="/cache"
                                component={CACHE}
                            />
                            
                            <PrivateRoute
                                exact
                                path="/coupons"
                                component={Coupons}
                            />
                            <PrivateRoute
                                exact
                                path="/create-coupon"
                                component={CreateCoupon}
                            />
                            <PrivateRoute
                                exact
                                path="/notifications"
                                component={Notifications}
                            />
                            <PrivateRoute
                                exact
                                path="/create-notification"
                                component={CreateNotification}
                            />
                            <PrivateRoute
                                exact
                                path="/users"
                                component={Users}
                            />
                            <PrivateRoute
                                exact
                                path="/users/:id"
                                component={User}
                            />
                            <PrivateRoute
                                exact
                                path="/create"
                                component={Create}
                            />
                            <PrivateRoute
                                exact
                                path="/"
                                component={Dashboard}
                            />
                        </Switch>
                    </Router>
                </React.Suspense>
            </AuthProvider>
        </ApolloProvider>
    );
}

export default App;
