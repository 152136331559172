import React from 'react'
import styled from 'styled-components'
import logo from '../assets/images/logo.png'

const StyledLogo = styled.img`
  height: 58px;
  width: 57px;
  display: block;
  margin: 0 auto;
`

function Logo({ style }) {
  return <StyledLogo style={style} src={logo} alt='Logo Delfino.cr' />
}

export { Logo }
